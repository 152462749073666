import showToast from '@shared/modules/showToast'

export default (err: any): void => {
    console.error(err)

    let msg = Lang.get('common.server_error')

    if (!err || !err.response || !err.response.data) {
        showToast({ text: msg, success: false })
        return
    }

    showToast({
        text: err.response.data.message || Lang.get('common.server_error'),
        success: false,
    })
}
