<script setup lang="ts">
import { computed } from 'vue'

type Props = {
    columns?: number
}

const { columns = 6 } = defineProps<Props>()
const columnsClass = computed(() => {
    switch (columns) {
        case 5:
            return 'md:grid-cols-[70px_auto_110px_120px_110px]'
        default:
            return 'md:grid-cols-[70px_auto_110px_120px_110px_60px]'
    }
})
</script>

<template>
    <div
        class="bg-page border-b border-border last:border-none grid grid-cols-[100px_auto_90px] text-sm"
        :class="columnsClass"
    >
        <slot />
    </div>
</template>
