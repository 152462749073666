<script setup lang="ts">
import type { Product } from '@shared/types/models'
import { computed } from 'vue'

interface Props {
    availability: Product['availability']
    showAll?: boolean
}

const { availability, showAll } = defineProps<Props>()

const availabilityTitle = computed(() => generateAvailabilityTitle())
const availabilityColor = computed(() => generateAvailabilityColor())

function generateAvailabilityTitle(): string {
    switch (availability) {
        case 'in':
            return Lang.get('common.in_stock')
        case 'out':
            return Lang.get('common.not_in_stock')
        case 'order':
            return Lang.get('common.on_order')
        default:
            return ''
    }
}

function generateAvailabilityColor(): string {
    switch (availability) {
        case 'in':
            return 'text-main-green-hover'
        case 'out':
            return 'text-gray-500'
        case 'order':
            return 'text-main-orange'
        default:
            return 'text-gray-600'
    }
}

const condition = computed(() => showAll ? true : (availability !== 'in'))
</script>

<template>
    <span
        v-if="condition"
        :class="availabilityColor"
        aria-label="availability"
    >
        {{ availabilityTitle }}
    </span>
</template>
