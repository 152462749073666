<script setup lang="ts">
import { onMounted, ref } from 'vue'
import Spinner from '@shared/components/Spinner.vue'
import axios from 'axios'
import handleServerError from '@shared/modules/handleServerError'

type PortmoneFormData = {
    action: string
    bodyRequest: object
}

type Props = {
    orderToken: string
}

const { orderToken } = defineProps<Props>()

const formData = ref<PortmoneFormData | null>(null)
const loading = ref<boolean>(true)

onMounted(() => fetchFormData())

function fetchFormData(): void {
    loading.value = true

    const params = { order: orderToken }

    axios.get<PortmoneFormData>('/api/public/payment/form-data', { params })
        .then(resp => formData.value = resp.data)
        .catch(handleServerError)
        .finally(() => loading.value = false)
}
</script>

<template>
    <spinner v-if="loading" />

    <h2 v-else-if="!formData" class="text-red-700">
        😔 {{ $trans.get('common.server_error') }}
    </h2>

    <form
        v-else
        :action="formData.action"
        method="post"
        target="_blank"
        class="m-0 inline-block"
    >
        <input type="hidden" name="bodyRequest" :value="JSON.stringify(formData.bodyRequest)" />
        <input type="hidden" name="typeRequest" value="json" />

        <slot />
    </form>
</template>
