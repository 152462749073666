import axios from 'axios'
import showToast from '@shared/modules/showToast'

export default () => {
    function addToWishlist(productId: number): void {
        if (!Auth) {
            return
        }

        const params = {
            product_id: productId,
            token: Auth.token,
        }

        axios.post('/api/v2/wishlist', params)
            .then(() => showToast({ text: Lang.get('common.product_added_to_wishlist') }))
            .catch(err => console.error(err))
    }

    function removeFromWishlist(productId: number): void {
        if (!Auth) {
            return
        }

        const params = {
            product_id: productId,
            token: Auth.token,
        }

        axios.delete('/api/v2/wishlist', { params })
            .then(() => showToast({ text: Lang.get('common.product_removed_from_wishlist') }))
            .catch(err => console.error(err))
    }

    return {
        addToWishlist,
        removeFromWishlist,
    }
}
